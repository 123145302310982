<script lang="ts">
	import { onMount, onDestroy } from 'svelte';
	import { page } from '$app/stores';
	import type { User } from 'firebase/auth';
	import authStore from '$lib/authStore';
	import Header from '$components/Header.svelte';
	import LoginOrSignUpModal from '$components/LoginOrSignUpModal.svelte';
	import { posthog, initPosthog } from '$lib/initPosthog';

	let subscriptions: (() => void)[] = [];
	let user: User | null | undefined = undefined;

	let loginOrSignUpModal: LoginOrSignUpModal;
	let loginOrSignupWhichModal: 'login' | 'sign-up' = 'sign-up';

	onMount(async () => {
		initPosthog();
		localStorage.setItem('redirectUrl', $page.url.pathname);

		document.body.style.overflowY = 'auto';
		document.body.style.background = '#e6e4d6';

		subscriptions.push(
			authStore.subscribe(async (auth) => {
				user = auth.user;

				// we don't know their sign-in status yet
				if (user === undefined) {
					return;
				}

				// user is not logged in
				if (user === null) {
					// earlyAccessIsOnline = true;
					// userHasAccess = false;
					return;
				}

				if (user.email) {
					posthog.identify(user.email);
				}

				// landing is only 'true' when they navigate to this page from the header
				// so, if they're logged in and go to iliad.ai, we redirect them to /canvas
				// if ($page.url.searchParams.get('landing') !== 'true') {
				// 	goto('/canvas', { replaceState: true });
				// }
			})
		);
	});

	onDestroy(() => {
		for (const _ of subscriptions) {
			_();
		}
	});
</script>

<svelte:head>
	<title>Iliad: Expressive and controllable visual art generation</title>
	<meta
		name="description"
		content="Create and transform images with ease. Enjoy granular control, seamless textures, pressure-sensitive brush input, and a mobile-friendly design."
	/>
	<script defer type="application/ld+json">
		{
			"@context": "https://schema.org",
			"@type": "WebApplication",
			"name": "Iliad",
			"description": "Iliad is an AI tool for creating and transforming images with ease and control.",
			"url": "https://iliad.ai",
			"applicationCategory": "Design",
			"offers": [
				{
					"@type": "Offer",
					"price": "0",
					"priceCurrency": "USD",
					"description": "Free tier: Create up to 50 images per day.",
					"url": "https://iliad.ai"
				},
				{
					"@type": "Offer",
					"price": "15",
					"priceCurrency": "USD",
					"description": "Iliad Pro: Unlimited image creation for $15/month.",
					"url": "https://iliad.ai/pricing"
				}
			],
			"featureList": [
				"Image creation",
				"Granular control over generation",
				"Seamless texture and pattern creation",
				"Pressure-sensitive brush input",
				"Mobile support"
			],
			"publisher": {
				"@type": "Organization",
				"name": "Iliad",
				"url": "https://iliad.ai"
			},
			"image": "https://iliad.ai/LightMode-FullLogo-TransparentBG.svg"
		}
	</script>
	<link rel="preload" fetchpriority="high" as="image" href="/pale-blue3.avif" type="image/avif" />
	<link
		rel="preload"
		href="/fonts/crimson-text-v19-latin-regular.woff2"
		as="font"
		type="font/woff2"
		crossorigin="anonymous"
	/>
</svelte:head>

<div class="w-screen min-h-screen h-fit font-inter bg-primary-100 overflow-x-hidden">
	<div
		id="page"
		class="w-screen h-fit flex flex-col"
		style="
			background-image: linear-gradient(
				180deg,
				hsl(53deg 24% 87%) 0%,
				hsl(52deg 23% 86%) 11%,
				hsl(51deg 22% 86%) 22%,
				hsl(51deg 21% 85%) 33%,
				hsl(50deg 20% 85%) 44%,
				hsl(49deg 19% 84%) 56%,
				hsl(49deg 18% 84%) 67%,
				hsl(48deg 17% 83%) 78%,
				hsl(48deg 16% 83%) 89%,
				hsl(47deg 15% 82%) 100%
			);"
	>
		<div class="w-full h-12 p-2 flex flex-row justify-between">
			<Header />
			<div class="flex flex-row justify-center items-center">
				<a
					class="w-[7rem] h-8 flex flex-row justify-center items-center rounded text-zinc-900 bg-primary-100 hover:bg-primary-200 font-semibold text-sm hover:shadow-md"
					href="/plan"
				>
					Pricing
				</a>
				{#if user}
					<div
						class="w-[7rem] h-8 flex flex-row justify-center items-center rounded text-emerald-800 bg-primary-100 border-zinc-400 font-semibold text-sm"
					>
						Logged in <i class="fa-solid fa-check ml-2 text-emerald-800" />
					</div>
				{:else}
					<button
						class="w-[7rem] h-8 flex flex-row justify-center items-center rounded text-zinc-900 bg-primary-100 hover:bg-primary-200 border-zinc-400 font-semibold text-sm hover:shadow-md"
						on:pointerup={() => {
							if (user === undefined) {
								return;
							}
							loginOrSignupWhichModal = 'login';
							loginOrSignUpModal.openDialog();
						}}
					>
						Log in <i class="fa-solid fa-sign-in ml-2" />
					</button>
				{/if}
				<div class="w-2" />
			</div>
		</div>
		<div class="w-full h-fit flex flex-col justify-start items-center">
			<div class="h-32" />
			<img
				class="inline h-[80px] w-[246px] md:h-[128px] md:w-[393px]"
				src="/LightMode-FullLogo-TransparentBG.svg"
				alt=""
			/>
			<div class="h-20" />
			<div id="headline-section" class="w-full h-fit flex flex-col justify-center items-center">
				<div
					class="max-w-[400px] sm:max-w-[475px] md:max-w-[600px] px-4 md:px-2 text-3.25xl sm:text-4.25xl md:text-5xl text-center font-crimson text-zinc-900 z-10"
				>
					Create and transform images with ease and control.
				</div>
			</div>
			<div class="w-full h-fit flex flex-row justify-center items-center">
				<div
					class="w-5/6 h-96 absolute bg-cover"
					style="
						background-image: url('/pale-blue3.avif');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 50%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
				/>
				<div class="w-full h-72 sm:h-80 flex flex-col justify-center items-center opacity-[99.99%]">
					<div class="w-fit h-fit flex flex-col justify-center items-center">
						{#if user}
							<a
								class="w-[12.5rem] h-16 mt-6 px-3 flex flex-row justify-center items-center border rounded text-md font-semibold text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
								href="/canvas"
							>
								Go to canvas
							</a>
						{:else}
							<button
								class="w-[12.5rem] h-16 mt-6 px-3 flex flex-row justify-center items-center border rounded text-md font-semibold text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
								on:click={() => {
									if (user === undefined) {
										return;
									}
									loginOrSignupWhichModal = 'sign-up';
									loginOrSignUpModal.openDialog();
								}}
							>
								Go to canvas
							</button>
						{/if}
					</div>
				</div>
			</div>
			<div class="h-20" />
			<div
				class="w-[340px] sm:w-[520px] md:w-[625px] text-xl md:text-2xl font-semibold opacity-[99.99%]"
			>
				Transform and riff on your ideas.
			</div>
			<div class="h-8"></div>
			<div class="w-[340px] sm:w-[520px] md:w-[625px] text-[16px] opacity-[99.99%]">
				<p>
					Iliad's <span class="font-semibold">transform</span> setting gives you granular control over
					your generations.
				</p>
				<div class="h-4" />
				<p>
					Start with a sketch or upload, adjust the transform, and guide your visual ideas into
					refined images.
				</p>
			</div>
			<div class="h-8"></div>
			<div class="flex flex-row justify-center items-center opacity-[99.99%]">
				<div class="flex flex-col justify-center items-start">
					<div class="flex flex-row justify-center items-center">
						<img
							class="w-24 h-24 sm:w-32 sm:h-32 md:w-44 md:h-44 lg:w-56 lg:h-56 rounded shadow-md"
							src="/mushroom-1.avif"
							alt=""
						/>
						<div class="w-[5px]" />
						<div class="w-0 sm:w-2" />
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							fill="currentColor"
							class="w-4 h-4 sm:w-6 sm:h-6 text-cyan-500 opacity-70"
						>
							<path d="M10.029 5H0v7.967h10.029V18l9.961-9.048L10.029 0v5z" />
						</svg>
						<div class="w-[2px]" />
						<div class="w-0 sm:w-2" />
					</div>
					<div class="h-4" />
					<div class="w-24 sm:w-32 md:w-44 lg:w-56 h-12 text-center text-xs opacity-70">
						Simple sketch created with Iliad's brush tool.
					</div>
				</div>
				<div class="flex flex-col justify-center items-start">
					<div class="flex flex-row justify-center items-center">
						<img
							class="w-24 h-24 sm:w-32 sm:h-32 md:w-44 md:h-44 lg:w-56 lg:h-56 rounded shadow-md"
							src="/mushroom-2.avif"
							alt=""
						/>
						<div class="w-[5px]" />
						<div class="w-0 sm:w-2" />
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							fill="currentColor"
							class="w-4 h-4 sm:w-6 sm:h-6 text-cyan-500 opacity-70"
						>
							<path d="M10.029 5H0v7.967h10.029V18l9.961-9.048L10.029 0v5z" />
						</svg>
						<div class="w-[2px]" />
						<div class="w-0 sm:w-2" />
					</div>
					<div class="h-4" />
					<div class="w-24 sm:w-32 md:w-44 lg:w-56 h-12 text-center text-xs opacity-70">
						78% transform with prompt "blue mushroom with golden flecks".
					</div>
				</div>
				<div class="flex flex-col justify-center items-start">
					<div class="flex flex-row justify-center items-center">
						<img
							class="w-24 h-24 sm:w-32 sm:h-32 md:w-44 md:h-44 lg:w-56 lg:h-56 rounded shadow-md"
							src="/mushroom-3.avif"
							alt=""
						/>
					</div>
					<div class="h-4" />
					<div class="w-24 sm:w-32 md:w-44 lg:w-56 h-12 text-center text-xs opacity-70">
						Another pass at the same transform.
					</div>
				</div>
			</div>
			<div class="h-20" />
			<div class="w-[340px] sm:w-[520px] md:w-[625px] flex flex-row justify-center items-center">
				<div
					id="texture-authoring-section"
					class="w-5/6 h-96 absolute bg-cover"
					style="background-image: url('/portal-2.avif');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
				/>
				<div
					class="w-full h-fit flex flex-col lg:flex-row
						   justify-center items-center opacity-[99.99%]"
				>
					<div
						class="w-full flex flex-col justify-center
							   items-start text-left
							   max-w-2xl"
					>
						<div
							class="text-xl
								   md:text-2xl
								   font-semibold"
						>
							Synthesize textures and patterns.
						</div>
						<div class="w-full h-8" />
						<div
							class="w-fit lg:w-72 max-w-lg lg:max-w-lg flex flex-col justify-center items-center text-md"
						>
							<p>
								Easily create unique, seamless textures for 3D modeling, tile creation, and more.
							</p>
						</div>
					</div>
					<div class="w-[36rem] h-[324px] lg:h-[372px] flex flex-col justify-end items-center">
						<div class="h-0 lg:h-12"></div>
						{#await import('$components/PBRMaterialGraphic.svelte') then Module}
							<Module.default width={300} height={300} transitionDuration={3000} />
						{/await}
						<div class="w-48 h-6 text-center text-xs opacity-70">
							Example of an Iliad seamless texture applied to a sphere.
						</div>
					</div>
				</div>
			</div>
			<div class="h-32" />
			<div class="w-[340px] sm:w-[520px] md:w-[625px] text-xl md:text-2xl font-semibold">
				Designed to support your creativity.
			</div>
			<div class="h-8"></div>
			<div
				class="w-[340px] sm:w-[520px] md:w-[625px] flex flex-row justify-start items-center flex-wrap"
			>
				<div
					class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
				>
					<i class="fa-regular fa-images"></i>
					<div class="w-2"></div>
					Free for up to 50 images per day
				</div>
				<div
					class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
				>
					<i class="fa-solid fa-paintbrush"></i>
					<div class="w-2"></div>
					Pressure-sensitive brush tool
				</div>
				<div
					class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
				>
					<i class="fa-solid fa-paragraph"></i>
					<div class="w-2"></div>
					Prompt organization
				</div>
				<div
					class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
				>
					<i class="fa-solid fa-cubes"></i>
					<div class="w-2"></div>
					Library to optionally save your work
				</div>
				<div
					class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
				>
					<i class="fa-solid fa-sailboat"></i>
					<div class="w-2"></div>
					Journeys to share your process
				</div>
				<div
					class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
				>
					<i class="fa-solid fa-mobile"></i>
					<div class="w-2"></div>
					Mobile-friendly website
				</div>
			</div>
			<div class="h-8"></div>
			<div class="w-[340px] sm:w-[520px] md:w-[625px] text-[16px]">
				We've worked hard to make Iliad feel intuitive, flexible, and maybe even fun to use. We hope
				it becomes a useful part of your process.
			</div>
			<div class="w-full h-fit flex flex-row justify-center items-center">
				<div
					id="cta2-section"
					class="w-5/6 h-48 absolute bg-cover"
					style="background-image: url('/portal-1.avif');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 50%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
				/>
				<div class="w-full h-48 flex flex-col justify-center items-center opacity-[99.99%]">
					<div class="w-fit h-fit flex flex-col justify-center items-center">
						{#if user}
							<a
								class="w-[12.5rem] h-16 mt-6 px-3 flex flex-row justify-center items-center border rounded text-md font-semibold text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
								href="/canvas"
							>
								Try Iliad
							</a>
						{:else}
							<button
								class="w-[12.5rem] h-16 mt-6 px-3 flex flex-row justify-center items-center border rounded text-md font-semibold text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
								on:click={() => {
									if (user === undefined) {
										return;
									}
									loginOrSignupWhichModal = 'sign-up';
									loginOrSignUpModal.openDialog();
								}}
							>
								Try Iliad - free
							</button>
						{/if}
					</div>
				</div>
			</div>
			<div class="h-32" />
			<div>
				<div
					id="testimonial-section"
					class="w-5/6 h-96 absolute bg-cover"
					style="
						background-image: url('/pale-blue2.avif');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
				/>
				<div
					class="w-[340px] sm:w-[520px] md:w-[625px] text-xl md:text-2xl font-semibold opacity-[99.99%]"
				>
					We'd love to see what you make.
				</div>
				<div class="h-8"></div>
				<div class="w-[340px] sm:w-[520px] md:w-[625px] text-[16px] opacity-[99.99%]">
					<i class="w-2" />
					<a class="text-cyan-700 hover:brightness-150" href="https://discord.gg/5rQP6KTR4X">
						Join Iliad's <i class="fa-brands fa-discord" /> Discord community
					</a>
					<i class="w-2" />
					to share your work, give feedback, and connect with other creators.
				</div>
				<div class="h-8" />
				<div
					class="w-[340px] sm:w-[520px] md:w-[625px] flex flex-row justify-center items-center opacity-[99.99%]"
				>
					<div class="hidden sm:block">
						<img
							class="h-[73px] w-[70px] rounded-[100px] bg-[#2c2d31] border-[#2c2d31] border-[9px]"
							alt="happy user from discord"
							src="/unparalleled-avatar2.png"
							loading="lazy"
						/>
					</div>
					<div class="hidden sm:block">
						<div class="w-2" />
					</div>
					<img
						class="rounded-lg w-[340px] h-[57px] sm:w-[431px] sm:h-[72px]"
						alt="testimonial"
						src="/unparalleled.avif"
						loading="lazy"
					/>
				</div>
				<div class="h-6"></div>
				<div class="w-[340px] sm:w-[520px] md:w-[625px] text-right text-sm opacity-70">
					<!-- comfybunni -->
					- happy user from the Discord
				</div>
			</div>
			<div class="h-12" />
			<div class="opacity-[0.01] pointer-events-none">
				<a
					href="https://theresanaiforthat.com/ai/iliad/?ref=featured&v=4042902"
					target="_blank"
					rel="nofollow"
					><img
						alt="taaft-ref"
						width="224"
						height="46"
						src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"
						loading="lazy"
					/></a
				>
			</div>
			<div class="h-12" />
		</div>
	</div>
</div>

<LoginOrSignUpModal bind:this={loginOrSignUpModal} bind:whichModal={loginOrSignupWhichModal} />

<!-- others used:
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/368c0398-5a1d-4b65-25c8-5a732155e100/public
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/9698a2db-e1a3-42f8-3ce5-70233ceff100/public
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/a677e83c-795c-47c9-59ff-1eac6ca86b00/public
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/66e33069-b01c-41ba-5128-984c6385bc00/public
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/c35e35c2-91b7-46f4-1271-6c275db62b00/public
-->
