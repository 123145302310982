// import { PUBLIC_ENVIRONMENT } from '$env/static/public';
// import posthog from 'posthog-js';

// export const initPosthog = () => {
// 	const devPosthogId = 'phc_lajN6lUlP8U4PSCo3YqyXIzU42ccUSc91QHamXvKlHY';
// 	const prodPosthogId = 'phc_p7EDVq5HcrSd2qzxX8rcNj7kbAO2q5k1E1YcQptdA72';
// 	const posthogId =
// 		PUBLIC_ENVIRONMENT === 'prod'
// 			? prodPosthogId
// 			: PUBLIC_ENVIRONMENT === 'local' || PUBLIC_ENVIRONMENT === 'dev'
// 				? devPosthogId
// 				: undefined;

// 	if (!posthogId) {
// 		console.error('Posthog not initialized due to invalid environment.');
// 		return;
// 	}

// 	posthog.init(posthogId, {
// 		api_host: 'https://us.i.posthog.com',
// 		session_recording: { maskAllInputs: false }
// 	});
// };

import { PUBLIC_ENVIRONMENT } from '$env/static/public';
// import 'posthog-js/dist/recorder';
// import 'posthog-js/dist/web-vitals';
// import posthog from 'posthog-js/dist/module.no-external';
import posthog from 'posthog-js';

export { posthog };

export const initPosthog = () => {
	const devPosthogId = 'phc_lajN6lUlP8U4PSCo3YqyXIzU42ccUSc91QHamXvKlHY';
	const prodPosthogId = 'phc_p7EDVq5HcrSd2qzxX8rcNj7kbAO2q5k1E1YcQptdA72';
	const posthogId =
		PUBLIC_ENVIRONMENT === 'prod'
			? prodPosthogId
			: PUBLIC_ENVIRONMENT === 'local' || PUBLIC_ENVIRONMENT === 'dev'
				? devPosthogId
				: undefined;

	if (!posthogId) {
		console.error('PostHog not initialized due to invalid environment.');
		return;
	}

	// Defer initialization until DOMContentLoaded
	if (document.readyState === 'loading') {
		window.addEventListener('DOMContentLoaded', () => {
			console.log('inited after dom load');
			posthog.init(posthogId, {
				api_host: 'https://us.i.posthog.com',
				session_recording: { maskAllInputs: false }
			});
		});
	} else {
		console.log('dom was ready - init');
		posthog.init(posthogId, {
			api_host: 'https://us.i.posthog.com',
			session_recording: { maskAllInputs: false }
		});
	}
};
