<script lang="ts">
	import { createDialog } from 'svelte-headlessui';
	import { signOut, type User } from 'firebase/auth';
	import { auth } from '$lib/firebase';
	import authStore from '$lib/authStore';
	import { posthog } from '$lib/initPosthog';
	import Transition from 'svelte-transition';

	export let light = true;
	export let fixed: boolean = false;

	export let user: User | undefined | null = undefined;
	export let journeyId: string | undefined = undefined;
	export let userIsOwner: boolean | undefined = undefined;

	const dialog = createDialog({ label: 'Menu' });

	async function logout() {
		try {
			posthog.reset();
			await signOut(auth);
		} catch (error: any) {
			console.log(error);
		}
	}

	function handleWindowPointerDown(e: PointerEvent) {
		if (!$dialog.expanded) return;
		if (!e.target) return;
		if (!(e.target as HTMLElement).closest('#header-menu')) {
			$dialog.expanded = false;
		}
	}
</script>

<svelte:window on:pointerdown={handleWindowPointerDown} />

<div id="header-menu" class="flex flex-row z-20">
	<div class="flex flex-row justify-start items-center">
		<button
			aria-label="Menu"
			class={`
						w-8 h-8 flex flex-row justify-center items-center text-md rounded
						${
							light
								? `text-primary-600 bg-primary-100 hover:bg-primary-200`
								: `text-zinc-400 border border-zinc-800 hover:border-zinc-700 bg-zinc-900 hover:bg-zinc-800 hover:text-zinc-300 shadow-sm`
						}
					`}
			on:click={() => {
				$dialog.expanded = !$dialog.expanded;
			}}
		>
			<i class="fa-solid fa-bars" />
		</button>
		<Transition show={$dialog.expanded}>
			<div
				class={`${
					fixed ? 'fixed' : 'absolute'
				} top-10 left-2 w-52 h-fit flex flex-col justify-start items-center text-zinc-100 bg-navy-900 rounded border border-zinc-800 text-xs shadow-md`}
			>
				<div class="h-1.5" />
				{#if $authStore.isLoggedIn}
					<div class="w-[196px] h-[4rem] py-2 px-1 overflow-x-hidden overflow-y-hidden">
						Signed in as
						<div class="h-0.5" />
						<p>
							<b>{$authStore.user?.displayName ? $authStore.user?.displayName : ''}</b>
						</p>
						<p>
							{$authStore.user?.email}
						</p>
					</div>
				{:else}
					<div
						class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150"
					>
						<a href="/login" class="flex flex-row justify-start items-center w-full py-2 px-1">
							<div class="w-6 flex flex-row justify-center items-center">
								<i class="fa-solid fa-sign-in text-zinc-200" />
							</div>
							<div class="w-1.5"></div>
							Log in
						</a>
					</div>
				{/if}
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<div class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150">
					<a href="/canvas" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-paintbrush text-cyan-400" />
						</div>
						<div class="w-1.5"></div>
						Canvas
					</a>
				</div>
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<div class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150">
					<!-- <a href="/pages" class="block w-full py-2 px-1"
						><i class="fa-solid fa-book-open text-[#dab488] pl-1 pr-2.5" />Notebook</a
					> -->

					<a href="/journeys" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-sailboat text-[#dab488]" />
						</div>
						<div class="w-1.5"></div>
						Journeys
					</a>
					<!-- orange 200 to 79.2% lch lightness (cyan-400): #dab488 -->
				</div>
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<div class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150">
					<a href="/library" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-images text-rose-400" />
						</div>
						<div class="w-1.5"></div>
						Image Library
					</a>
				</div>
				<div class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150">
					<a href="/library-3d" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-cubes text-rose-400" />
						</div>
						<div class="w-1.5"></div>
						3D Library
					</a>
				</div>
				<div class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150">
					<a href="/boards" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-thumbtack text-rose-400" />
						</div>
						<div class="w-1.5"></div>
						Boards
					</a>
				</div>
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<div class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150">
					<a
						href="https://discord.gg/5rQP6KTR4X"
						class="flex flex-row justify-start items-center w-full py-2 px-1"
						on:click={(e) => {
							e.preventDefault();
							posthog.capture('click_header_discord_link', {
								user_id: user?.uid,
								journey_id: journeyId,
								is_owner: userIsOwner
							});
							window.open('https://discord.gg/5rQP6KTR4X', '_blank');
						}}
					>
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-brands fa-discord text-[#6d7dcd]" />
						</div>
						<div class="w-1.5"></div>
						Iliad Discord
					</a>
				</div>
				<div class="h-1.5" />
				<div class="w-full h-0 border-t border-zinc-800" />
				<div class="h-1.5" />
				<div class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150">
					<a
						href="/account-settings"
						class="flex flex-row justify-start items-center w-full py-2 px-1"
					>
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-user-gear text-emerald-400" />
						</div>
						<div class="w-1.5"></div>
						Account Settings
					</a>
				</div>
				<div class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150">
					<a href="/plan" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-tags text-emerald-400" />
						</div>
						<div class="w-1.5"></div>
						Pricing
					</a>
				</div>
				{#if $authStore.isLoggedIn}
					<div class="h-1.5" />
					<div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<div
						class="w-[196px] h-fit bg-navy-900 rounded focus:brightness-150 hover:brightness-150"
					>
						<a
							href="/"
							class="flex flex-row justify-start items-center w-full py-2 px-1"
							on:click={logout}
						>
							<div class="w-6 flex flex-row justify-center items-center">
								<i class="fa-solid fa-sign-out text-zinc-400" />
							</div>
							<div class="w-1.5"></div>
							Sign out
						</a>
					</div>
				{/if}
				<div class="h-1.5" />
			</div>
		</Transition>
	</div>
</div>
