<script lang="ts">
	import { onMount } from 'svelte';
	import { goto } from '$app/navigation';
	import { posthog, initPosthog } from '$lib/initPosthog';
	import {
		signInWithPopup,
		signInWithEmailAndPassword,
		createUserWithEmailAndPassword,
		getRedirectResult
	} from 'firebase/auth';
	import type { User } from 'firebase/auth';
	import { auth, googleProvider } from '$lib/firebase';
	import { createDialog } from 'svelte-headlessui';
	import Transition from 'svelte-transition';
	import { browser } from '$app/environment';

	export let currentUser: User | undefined | false = false;

	export let whichModal: 'login' | 'sign-up' = 'login';
	export let loginTitleMessage: string = 'Log in to Iliad to continue.';
	export let signUpTitleMessage: string = 'Sign up for Iliad to continue.';

	let email: string = '';
	let password: string = '';
	let errorMessage: string = '';

	const dialog = createDialog({ label: 'Login or Sign Up' });

	$: {
		if (browser) {
			if ($dialog.expanded) {
				document.body.style.overflowY = 'hidden';
			} else {
				document.body.style.overflowY = 'auto';
			}
		}
	}

	export function openDialog() {
		console.log('opening dialog');
		dialog.open();
	}

	export function closeDialog() {
		console.log('closing dialog');
		dialog.close();
	}

	async function loginWithGoogle() {
		try {
			await signInWithPopup(auth, googleProvider);
			// navigate to canvas, keeping it so back button works
			goto('/canvas');
		} catch (error) {
			console.log('loginWithGoogle error', error);
			errorMessage = 'Failed to log in with Google.';
		}
	}

	async function loginWithEmailPassword() {
		try {
			await signInWithEmailAndPassword(auth, email, password);
		} catch (error) {
			console.log('loginWithEmailPassword error', error);
			errorMessage = 'Invalid email or password.';
		}
	}

	async function signUpWithEmailAndPassword() {
		try {
			await createUserWithEmailAndPassword(auth, email, password);
		} catch {
			errorMessage = 'Something went wrong.';
			// Account with this email already exists
			// TODO: there are probably more possible error messages
		}
	}

	onMount(async () => {
		initPosthog();
		console.log('login onMount');

		auth.onAuthStateChanged(async (user) => {
			try {
				const redirectResult = await getRedirectResult(auth);
				console.log('redirectResult', redirectResult);
			} catch (error) {
				console.log('redirect result error', error);
			}

			currentUser = user ?? undefined;
			if (user) {
				if (user.email) {
					posthog.identify(user.email);
				}
			}
		});
	});
</script>

<div class={`fixed w-screen h-screen inset-0 ${$dialog.expanded ? 'z-50' : 'z-[-10]'}`}>
	<Transition show={$dialog.expanded}>
		<button
			class="w-full h-full bg-zinc-900 bg-opacity-25 cursor-default"
			on:click={dialog.close}
		/>
		<div
			class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-fit h-fit p-12 flex flex-col justify-center items-center text-zinc-200 bg-zinc-900 rounded border border-zinc-800 shadow-md z-50 font-inter text-xs"
			use:dialog.modal
		>
			<div
				class="w-[16rem] h-[32rem] sm:w-[22rem] sm:h-[36rem] flex flex-col justify-center items-center absolute-center-on-large"
			>
				<div class="absolute top-0 right-0 p-4">
					<button
						class="w-8 h-8 flex justify-center items-center rounded hover:bg-zinc-800 text-lg"
						on:click={dialog.close}
					>
						<i class="fa-solid fa-times" />
					</button>
				</div>
				{#if currentUser === false || currentUser}
					<div class="w-fit h-fit text-primary-600">
						<i class="fa-solid fa-circle-notch animate-spin text-4xl" />
					</div>
				{/if}

				{#if currentUser === undefined}
					<div class="w-fit h-fit p-8 flex flex-col justify-center items-center">
						<img
							loading="lazy"
							class="w-24 h-24 rounded"
							src="https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/dbbc50c8-113f-468f-d9fe-44ace54c4000/public"
							alt=""
						/>
						<div class="h-8" />
						<div class="text-xl text-center">
							{whichModal === 'login' ? loginTitleMessage : signUpTitleMessage}
						</div>
						<div class="h-8" />
						<button
							class="
				w-72 h-12 px-3 flex flex-row justify-center items-center text-sm font-semibold
				rounded shadow-sm shadow-zinc-500 text-zinc-900 bg-zinc-200 hover:bg-zinc-50 border-zinc-400 hover:border-zinc-300 border
			"
							on:click={loginWithGoogle}
						>
							<i class="mr-5 fa-brands fa-google" />
							Continue with Google
						</button>
						<div class="h-4" />
						<div class="text-sm text-zinc-400">or</div>
						<div class="h-4" />
						{#if whichModal === 'login'}
							<input
								type="email"
								class="w-72 h-12 px-2 bg-navy-900 text-sm border rounded border-zinc-700"
								placeholder="Email"
								autocomplete="username"
								bind:value={email}
							/>
							<div class="h-3" />
							<input
								type="password"
								class="w-72 h-12 px-2 bg-navy-900 text-sm border rounded border-zinc-700"
								placeholder="Password"
								autocomplete="current-password"
								bind:value={password}
							/>
							<div class="h-3" />
							<button
								class="
					w-72 h-12 px-3 flex flex-row justify-center items-center text-sm font-semibold
					rounded shadow-sm bg-zinc-800 hover:bg-zinc-700 border
					border-zinc-700 hover:border-zinc-600
				"
								on:click={loginWithEmailPassword}
							>
								{whichModal === 'login' ? 'Log in' : 'Create account'}
							</button>
						{:else}
							<form on:submit|preventDefault={signUpWithEmailAndPassword} class="flex flex-col">
								<input
									type="email"
									class="w-72 h-12 px-2 bg-navy-900 text-sm border rounded border-zinc-700"
									placeholder="Email"
									autocomplete="username"
									bind:value={email}
									required
								/>
								<div class="h-3" />
								<input
									type="password"
									class="w-72 h-12 px-2 bg-navy-900 text-sm border rounded border-zinc-700"
									placeholder="Password"
									autocomplete="new-password"
									bind:value={password}
									required
								/>
								<div class="h-3" />
								<button
									type="submit"
									class="
						w-72 h-12 px-3 flex flex-row justify-center items-center text-sm font-semibold
						rounded shadow-sm bg-zinc-800 hover:bg-zinc-700 border
						border-zinc-700 hover:border-zinc-600
					"
								>
									Create account
								</button>
							</form>
						{/if}
						<div class="h-6" />
						{#if errorMessage !== ''}
							<div class="text-sm text-rose-300">{errorMessage}</div>
							<div class="h-4" />
						{/if}
						{#if whichModal === 'login'}
							<div class="h-4 text-sm">
								<a class="text-cyan-400 hover:text-cyan-300" href="/reset-password"
									>Reset password</a
								>
							</div>
							<div class="h-4" />
						{:else}
							<div class="h-4"></div>
							<div class="h-4" />
						{/if}
						{#if whichModal === 'login'}
							<div class="text-sm">
								No account?
								<button
									class="text-cyan-400 hover:text-cyan-300"
									on:pointerup={() => (whichModal = 'sign-up')}
								>
									Create one
								</button>
							</div>
						{:else}
							<div class="text-sm">
								Already have an account?
								<button
									class="text-cyan-400 hover:text-cyan-300"
									on:pointerup={() => (whichModal = 'login')}
								>
									Log in
								</button>
							</div>
						{/if}
					</div>
				{/if}
			</div>
		</div>
	</Transition>
</div>
